@font-face {
  font-family: "Technor";
  src:
    url("./public/fonts/Technor-Bold.woff2") format("woff2"),
    url("./public/fonts/Technor-Bold.woff") format("woff"),
    url("./public/fonts/Technor-Bold.eot") format("eot"),
    url("./public/fonts/Technor-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Technor medium";
  src:
    url("./public/fonts/Technor-Medium.woff2") format("woff2"),
    url("./public/fonts/Technor-Medium.woff") format("woff"),
    url("./public/fonts/Technor-Medium.eot") format("eot"),
    url("./public/fonts/Technor-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Technor regular";
  src:
    url("./public/fonts/Technor-Regular.woff2") format("woff2"),
    url("./public/fonts/Technor-Regular.woff") format("woff"),
    url("./public/fonts/Technor-Regular.eot") format("eot"),
    url("./public/fonts/Technor-Regular.ttf") format("truetype");
}

html {
  box-sizing: border-box;
  font-family: Technor;
}
body {
  margin: 0 !important;
  padding: 0 !important;
  width: calc(1260px + 2 * var(--horizontal-padding));
  background-color: #1b2330;
  color: #ffffff;
}
.home-block {
  background-color: #1b2330;
  padding: 30px 150px 0 150px;
}
.navigation-title {
  font-family: Technor;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  font-size: 36px;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 40vw;
  gap: 10px;
}
.hidden {
  display: none;
}
div.gm-style-iw.gm-style-iw-c {
  background-color: #1b2330;
  max-width: none !important;
  max-height: none !important;
}
.gm-style-iw-d {
  overflow: hidden !important;
  max-height: none !important;
}

.marker-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: Technor regular;
  height: 57px;
  width: 100%;
  border-bottom: 4px solid #c4c4c4 !important;
  background: #5e7812;
  text-align: center;
  cursor: pointer;
}
.col-2 {
  padding-left: 30px;
  padding-bottom: 30px;
  padding-right: 10px;
}
.avatar-icon {
  padding-left: 4px;
  padding-bottom: 5px;
  padding-right: 10px;
}
.marker-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.formularz {
  padding-left: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.send {
  font-size: 32px;
  width: 40%;
  border-bottom: 4px solid #c4c4c4;
  border-left: none;
  border-right: none;
  border-top: none;
  cursor: pointer;
  background: #c4c4c4;
  margin-left: auto;
}
.send.send-valid {
  background: #5e7812;
}

input,
textarea {
  height: 57px;
  background: transparent;
  flex-shrink: 0;
  color: #fff;
  font-family: Technor regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  border-bottom: 4px solid rgba(248, 252, 255, 0.3);
}

h1 {
  font-size: 64px;
  font-weight: 700;
}

h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 2px;
}
h3 {
  text-align: center;
  font-size: 96px;
  font-weight: 500;
}

.home-tlo {
  background-image: url("./public/tlo.jpg");
  height: 100vh;
  display: flex;
  flex-direction: row-reverse;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
p {
  max-width: 576px;
  font-family: Technor regular;
  font-size: 16px;
  line-height: 150%;
}
a {
  text-decoration: none;
  color: #fff;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hamburger-menu {
  display: none;
  cursor: pointer;
  padding: 10px;
}
label {
  font-family: Technor regular;
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
}
@media screen and (min-width: 600px) {
  .mobile-break {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .img-height {
    width: 300px;
    object-fit: cover;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block !important;
  }
  .zoom-button-object-group {
    position: absolute;
    top: 80%;
    left: 85%;
    display: flex !important;
    flex-direction: column;
  }
  .zoom-button-object {
    width: 40px;
    height: 40px;
    background-color: #29446b;
    margin: 5px;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: Technor;
    font-size: 25px;
    border: none;
  }
  .padding-left-extra {
    padding-left: 70px !important;
  }
  .photo-galery {
    display: flex;
    align-items: center;
    gap: 5%;
    flex-direction: column;
  }
  .parent {
    align-items: center;
  }
  .side-nav {
    display: flex;
    flex-direction: column;
  }
  .padding-right-extra {
    padding-right: 0 !important;
    margin-bottom: 0 !important;
  }
  .marker-button {
    margin-top: 20px;
  }
  .marker-nickname {
    margin-top: 20px !important;
  }
  .parent {
    flex-direction: column !important;
    flex-wrap: wrap;
  }
  .object-description {
    padding-left: 30px !important;
  }
  .formularz {
    padding-left: 0 !important;
  }
  .icon-padding-left {
    padding-left: 60px;
  }
  .col-1 {
    width: 100% !important;
  }
  .location-marker {
    flex-direction: column;
    padding-right: 10px;
  }
  .col-2 {
    width: 100%;
    padding-left: 0px;
  }
  .home-block {
    padding: 5px 50px 0 50px;
  }
  .interestings-one {
    flex-direction: column;
    padding: 0px 30px 0px 30px;
  }
  .group-objects {
    flex-direction: column !important;
    margin: 0 !important;
  }
  .group-objects-button {
    width: 100%;
  }
  .single-object {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
  }
  .interestings-two {
    flex-direction: column !important;
    padding: 0px 30px 0px 30px;
  }
  .img-sqr {
    width: 320px !important;
    height: 320px !important;
    object-fit: cover;
    padding: 10px 0 !important;
  }
  .hamburger-menu {
    display: block;
    justify-content: left;
    position: absolute;
    top: 0;
    z-index: 9999999;
    background-color: #1b2330;
  }
  .full-width {
    width: auto !important;
    height: 94vh !important;
  }
  li {
    width: 95vw !important;
  }
  h1 {
    font-size: 36px;
  }
  .padding-left-h1 {
    padding-left: 100px !important;
    padding-top: 5px !important;
  }
  .strzalka {
    left: 20px !important;
    top: 25px !important;
  }
  h2 {
    font-size: 36px !important;
  }
  form {
    max-width: 90vw !important;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .mobile-menu {
    display: none;
  }
  .mobile-menu.show {
    display: block;
    width: 100vw;
    padding-top: 20px;
  }
  .mobile-menu-bar {
    display: block;
  }

  .Navbar {
    display: none;
  }
}
.Map {
  display: flex;
}
.full-width {
  width: 600px;
  height: 100vh;
}
.gm-style-mtc {
  display: none;
}
.navigation-section {
  display: flex;
}
.navigation-icon {
  margin: 0px 50px 50px 50px;
}
.navigation-p {
  font-size: 18px;
}
ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
li {
  border-bottom: 4px solid rgba(248, 252, 255, 0.3);
  text-align: center;
  font-size: 20px;
  font-family: Technor regular;
  padding: 16px 8px;
  width: 470px;
}
li:last-child {
  border-bottom: none;
}
li:hover {
  background-color: #29446b;
}
.group-objects-button:hover {
  background: #3d4427;
}
.marker-cordinates:hover {
  color: #5e7812;
}
.send-valid:hover {
  background: #3d4427;
}
.strzalka {
  position: absolute;
  left: 50px;
  cursor: pointer;
  top: 60px;
}
.padding-left-h1,
.padding-left-extra {
  padding-left: 120px;
}
.group-objects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0px 50px 0px 100px;
  row-gap: 30px;
}
.location-marker-photo {
  width: 300px;
  height: 300px;
  object-fit: cover;
}
.location-marker {
  display: flex;
}
.marker-p {
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 0;
}
.group-objects-cordinates {
  display: flex;
  padding-bottom: 20px;
}
.marker-ikona {
  margin-right: 20px;
}
.group-objects-button {
  background: #5e7812;
  border-bottom: 4px solid #c4c4c4;
  font-size: 24px;
  font-family: Technor regular;
  padding: 16px 0px;
  display: block;
  width: 100%;
  text-align: center;
}
.interestings-one,
.interestings-two {
  display: flex;
  justify-content: space-evenly;
  gap: 10%;
  align-items: center;
  flex-wrap: wrap;
}
.interestings-two {
  flex-direction: row-reverse;
}
.img-sqr {
  width: 500px;
  height: 500px;
  object-fit: cover;
}
.single-object {
  padding-bottom: 20px;
}
.photo-galery {
  width: 50%;
}
.click-alert {
  position: absolute;
  top: 240px;
  z-index: 999999999;
  left: 10%;
  font-size: 32px;
  color: #21f378;
  font-weight: 700;
  text-transform: uppercase;
}
.small {
  width: 400px;
  height: 400px;
  padding: 20px 0px 20px 20px;
}
.parent {
  display: flex;
  flex-direction: row;
}
.col-1 {
  width: 50%;
}
.object-description {
  padding-left: 120px;
}
.LocationIcon {
  padding: 0px 10px 0px 0px;
}
.padding-right-extra {
  padding-right: 50px;
}
.custom-file-upload {
  padding: 20px 0 0;
}
.end {
  text-align: end;
}
.marker-nickname {
  margin-top: 0;
}
.nopage {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  margin: 0px 30px 0px 30px;
}

.zoom-button-object-group {
  display: none;
}

#root > main > div:nth-child(2) > div > div.gm-style > div:nth-child(13) > div {
  display: none;
}
#root
  > main
  > div:nth-child(2)
  > div
  > div.gm-style
  > div:nth-child(8)
  > button {
  display: none;
}
.gm-ui-hover-effect {
  display: none !important;
}
.mobile {
  display: none;
}
.img-height {
  height: 500px;
}
.none-list {
  border-bottom: none;
  text-align: left;
  font-size: 16px;
  padding: 0;
  width: auto;
}
.none-list:hover {
  background-color: #1b2330;
}
.single-icon-padding-left {
  display: inherit;
  padding-left: 60px;
}
.single-LocationIcon {
  padding: 15px 10px 0px 50px;
}
.marker-button:hover {
  background: #3d4427;
}
